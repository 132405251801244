export const formatNumber = (amount) => {
  return new Intl.NumberFormat('en-US').format(amount);
};

export const toTitleCase = (str) => {
  str = str.replace(/[_-]/g, ' '); // Remove underscores and dashes
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const convertToPositiveNumber = (nmbr) => {
  if (nmbr < 0) {
    nmbr *= -1;
  }
  return nmbr;
};

export const bdtFormat = (amount) => {
  return new Intl.NumberFormat('en-BD', {
    style: 'currency',
    currency: 'BDT',
  }).format(amount);
};

export const trimmedAndLowerCase = (value) => value.trim().toLowerCase();
