import { Card } from 'antd';
import { array, node, string } from 'prop-types';

const AppListCard = ({ title, icons, children, ...others }) => {
  return (
    <div className="custom-card">
      <Card bordered={false} {...others}>
        <div className="title-section">
          <span>{title}</span>
          {icons?.length > 0 && <div className="icons">{icons}</div>}
        </div>
        {children}
      </Card>
    </div>
  );
};

export default AppListCard;

AppListCard.propTypes = {
  title: string,
  icons: array,
  children: node,
};
