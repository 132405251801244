import { Col, Row, Typography } from 'antd';
import { bool, string, any } from 'prop-types';

const { Text } = Typography;

import './index.style.scss';

const AppListCardItem = ({ title, value, color, ...others }) => {
  return (
    <div className="custom-list-bg" {...others}>
      <Row style={{ backgroundColor: `${color && 'rgba(3, 88, 140, 0.02)'}` }}>
        <Col xs={8} style={{ marginBottom: 0 }}>
          <Text strong>{title}</Text>
        </Col>
        <Col xs={16} style={{ marginBottom: 0 }}>
          <Text className="vl-title">
            <div style={{ marginTop: '-1.5rem', marginLeft: '1rem' }}>
              {value ? (
                <div style={{ marginTop: '-1.2rem', marginLeft: '1rem' }}>{value}</div>
              ) : (
                <div style={{ marginLeft: '1rem' }}>{value}</div>
              )}
            </div>
          </Text>
        </Col>
      </Row>
    </div>
  );
};

AppListCardItem.propTypes = {
  title: string,
  value: any,
  color: bool,
};

export default AppListCardItem;
