/*eslint-disable*/
import PropTypes from 'prop-types';
import { List } from 'antd';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import { useViewNotification } from 'api/Notification.api';

import './index.scss';
import dayjs from 'dayjs';

const NotificationItem = ({ item, setNotifications }) => {
  const { mutate: viewNotification } = useViewNotification();

  const getDifference = (time) => {
    return dayjs(time).fromNow();
  };

  const onNotificationClick = async (e) => {
    try {
      e.stopPropagation();
      if (item.seen === 0) {
        viewNotification(item.id, {
          onSuccess: () => {
            setNotifications((notifications) =>
              notifications.map((notification) => {
                if (notification.id === item.id) {
                  notification.seen = 1;
                }
                return notification;
              }),
            );
          },
        });
      }
    } catch (error) {
      /*eslint-disable-next-line*/
      console.log(error);
    }
  };

  return (
    <List.Item onClick={onNotificationClick} className={item.seen === 0 ? `${item.type}` : ''}>
      <List.Item.Meta
        title={
          <div className="flex-between">
            <span className="title">{item.header}</span>
            <span className="time">{getDifference(item.created_at)}</span>
          </div>
        }
      />
      {item.description}
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  setNotifications: PropTypes.func.isRequired,
};
