import { Table } from 'antd';
import { array, bool, string } from 'prop-types';

const AppTableContainer = ({ columns, data, loading, ...rest }) => {
  const getRowClassName = (_, index) => {
    return index % 2 === 1 ? 'even-row' : 'odd-row'; // Apply 'even-row' class to every even row
  };

  return (
    <Table
      size="middle"
      rowClassName={getRowClassName}
      columns={columns}
      dataSource={data}
      loading={loading}
      scroll={{ x: 'auto' }}
      rowKey="id"
      pagination={{ pageSize: 10 }}
      {...rest}
    />
  );
};

export default AppTableContainer;

AppTableContainer.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  className: string,
  pagination: bool,
  loading: bool,
};

AppTableContainer.defaultProps = {
  pagination: true,
};
