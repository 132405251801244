import { node, string } from 'prop-types';
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';

const AppScrollBar = ({ children, className, ...others }) => {
  return (
    <SimpleBar {...others} className={className}>
      {children}
    </SimpleBar>
  );
};

export default AppScrollBar;

AppScrollBar.propTypes = {
  children: node.isRequired,
  className: string,
};
