import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AuthAPI, OrganizationManagerAPI } from 'utils/APIConfig';
import ErrorHandler from 'utils/ErrorHandler';
import { getItem } from 'utils/Helper';

export const useLogin = () => {
  return useMutation((body) => AuthAPI.post('/auth/signin-web?service_name=ecrm', body), { onError: ErrorHandler });
};

export const useFetchOrgTheme = () => {
  return useMutation(
    (id) =>
      OrganizationManagerAPI.get(`/org/theme/${id}`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    { onError: ErrorHandler },
  );
};

export const useSendOtp = () =>
  useMutation((body) => AuthAPI.post('/auth/send-otp?service_name=ecrm', body), { onError: ErrorHandler });

export const useVerifyOtp = () =>
  useMutation((body) => AuthAPI.post('/auth/verify-otp?service_name=ecrm', body), { onError: ErrorHandler });

export const useUserResetPassword = () =>
  useMutation((body) => AuthAPI.post('/auth/reset-password?service_name=ecrm', body), { onError: ErrorHandler });

export const useVerifyMFA = () =>
  useMutation(
    (body) =>
      AuthAPI.post('/auth/verify-mfa?service_name=ecrm', body, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tempToken')}`,
        },
      }),
    { onError: ErrorHandler },
  );

export const useResetPasswordFromProfile = () =>
  useMutation(
    (body) =>
      AuthAPI.patch('/auth/user/reset-password', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      onError: ErrorHandler,
    },
  );

export const useUpdateMFAStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (body) =>
      AuthAPI.patch('/auth/toggle-mfa-enables', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      onError: ErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(['get-mfa-info']);
      },
    },
  );
};

export const useGetMFAInfo = () =>
  useQuery(
    ['get-mfa-info'],
    () =>
      AuthAPI.get('/auth/get-mfa-code', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      select: ({ data: { data } }) => data,
      onError: ErrorHandler,
    },
  );

export const useGetNewMFA = () => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      AuthAPI.patch(
        '/auth/get-new-mfa',
        {},
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      ),
    {
      onError: ErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(['get-mfa-info']);
      },
    },
  );
};
