import { Select } from 'antd';
import { node } from 'prop-types';

const AppSelect = ({ children, ...other }) => {
  return (
    <Select showSearch allowClear optionFilterProp="children" maxTagCount={2} {...other}>
      {children}
    </Select>
  );
};

export default AppSelect;

AppSelect.propTypes = {
  children: node,
};
