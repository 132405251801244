import { useState, useEffect } from 'react';
import { List, Dropdown, Badge, Spin, Card, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppIcon } from 'components/AppComponent';
import AppScrollBar from 'components/AppScrollBar';
import NotificationItem from './NotificationItem';
import { useAuthContext } from 'contexts/Auth/index';
import { useNotifications, useViewNotifications } from 'api/Notification.api';

import './index.scss';

const Notification = () => {
  const { t } = useTranslation();
  const { socket } = useAuthContext();

  const { data: oldNotifications, isLoading } = useNotifications();
  const { mutate: mutateViewAll, isLoading: isAllViewing } = useViewNotifications();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(oldNotifications || []);
  }, [oldNotifications]);

  useEffect(() => {
    socket?.on('notification', (data) => {
      setNotifications((notifications) => {
        const notification = notifications.find((val) => val.id === data.id);
        if (notification) {
          return notifications;
        } else {
          return [data, ...notifications];
        }
      });
    });
  }, [socket]);

  const onViewAll = () => {
    const notSeen = [];

    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].seen === 0) notSeen.push(notifications[i].id);
    }

    if (notSeen.length === 0) return message.error("You don't have any unseen notifications");

    mutateViewAll(notSeen, {
      onSuccess: () => {
        setNotifications((notifications) =>
          notifications.map((notification) => {
            notification.seen = 1;
            return notification;
          }),
        );
      },
    });
  };

  return (
    <Dropdown
      menu={[]}
      trigger={['click']}
      dropdownRender={() => (
        <div className="notification-card">
          <Card
            title={`${t('int-notifications')} (${notifications?.length})`}
            extra={
              <Tooltip title="Mark all as read">
                <AppIcon
                  icon="akar-icons:double-check"
                  color="#005BD4"
                  style={{ width: 24, height: 24, marginTop: 4 }}
                  onClick={onViewAll}
                />
              </Tooltip>
            }>
            <AppScrollBar className="notification-details">
              <Spin spinning={isLoading || isAllViewing}>
                <List
                  dataSource={notifications}
                  renderItem={(item) => (
                    <NotificationItem key={item.id} item={item} setNotifications={setNotifications} />
                  )}
                />
              </Spin>
            </AppScrollBar>
          </Card>
        </div>
      )}>
      <Badge count={notifications?.filter((noti) => noti.seen == 0)?.length}>
        <AppIcon icon="eva:bell-fill" style={{ width: '24px', height: '24px' }} />
      </Badge>
    </Dropdown>
  );
};

export default Notification;
