import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const LangSelect = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('i18nextLng') || 'en');
  }, []);

  const Languages = [
    { value: 'en', lang: 'English' },
    { value: 'bn', lang: 'বাংলা' },
  ];

  return (
    <Select defaultValue={localStorage.getItem('i18nextLng')?.split('-')[0] || 'en'} onChange={handleLanguageChange}>
      {Languages.map((lang) => (
        <Option key={lang?.value} value={lang?.value}>
          {lang?.lang}
        </Option>
      ))}
    </Select>
  );
};

export default LangSelect;
