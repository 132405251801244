import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AppNotification } from 'components/AppComponent';
import { isValidToken } from 'lib/Jwt';
import { NotificationManagerAPI } from 'utils/APIConfig';
import ErrorHandler from 'utils/ErrorHandler';
import { getItem } from 'utils/Helper';

const queryOptions = {
  onError: ErrorHandler,
};

export const useOnlineUsersCount = () =>
  useQuery(
    ['active-users'],
    () =>
      NotificationManagerAPI.get('/users/online', {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'token')}` },
      }),
    {
      select: ({ data: { data } }) => data,
      ...queryOptions,
    },
  );

export const useViewNotification = () =>
  useMutation(
    (id) =>
      NotificationManagerAPI.patch(
        `/notification/seen/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      ),
    queryOptions,
  );

export const useCreateNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (body) =>
      NotificationManagerAPI.post('/notification/create', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(['mn-notification']);
        AppNotification('Notification has been scheduled.', 'success');
      },
    },
  );
};

export const useMnNotifications = () =>
  useQuery(
    ['mn-notification', getItem(localStorage, 'user_id')],
    () =>
      NotificationManagerAPI.get('/notification/manual-notifications', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      select: ({ data: { data } }) => data,
    },
  );

export const useDeleteMnNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      NotificationManagerAPI.delete(
        `/notification/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      ),
    {
      ...queryOptions,
      onSuccess() {
        Notification('Notification Deleted.', 'success');
        queryClient.invalidateQueries('get-all-notification');
      },
    },
  );
};

export const useUsersByLoc = () =>
  useMutation(
    (body) =>
      NotificationManagerAPI.post('/notification/get-users-by-location', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    queryOptions,
  );

export const useUsersByCampaigns = () =>
  useMutation(
    (body) =>
      NotificationManagerAPI.post('/notification/get-users-by-campaign', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    queryOptions,
  );

export const useNotifications = () =>
  useQuery(
    ['notifications'],
    () =>
      NotificationManagerAPI.get('/notification/get-notifications', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      enabled: isValidToken(getItem(localStorage, 'token')),
      select: ({ data: { data } }) => data,
    },
  );

export const useViewNotifications = () =>
  useMutation(
    (ids) =>
      NotificationManagerAPI.patch(
        `/notification/bulk-seen-notification`,
        { ids },
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')} `,
          },
        },
      ),
    queryOptions,
  );

export const useMnNotificationDetails = (notificationId) =>
  useQuery(
    ['mn-notification', notificationId],
    ({ queryKey }) =>
      NotificationManagerAPI.get(`/notification/manual-notification/${queryKey[1]}`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
      select: ({ data: { data } }) => data,
    },
  );

export const useUpdateMnNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (body) =>
      NotificationManagerAPI.put('/notification/update', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        queryClient.invalidateQueries('mn-notification');
      },
    },
  );
};
