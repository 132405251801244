/* eslint-disable */
import { Typography } from 'antd';
import { Component } from 'react';
import PropTypes from 'prop-types';

import { AppButton } from 'components/AppComponent';
import Error from 'assets/images/error.svg';
import './index.style.scss';

const { Title } = Typography;

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('error: ', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <img src={Error} alt="error" width={200} height="auto" className="mb-2" />
          <Title level={4} style={{ maxWidth: '700px' }}>
            Something went wrong while processing your action
          </Title>
          <Title level={5} style={{ fontWeight: 'normal', marginTop: 0, maxWidth: '700px', textAlign: 'center' }}>
            Please reload the page or consult with admin
          </Title>
          <div style={{ fontSize: 18, textAlign: 'center' }}>
            <AppButton onClick={() => window.location.reload()} type="primary" size="medium" className="mt-2">
              Reload page
            </AppButton>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
