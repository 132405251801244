import { Avatar, Modal, Space, Typography } from 'antd';
import { bool, func, number, object, oneOf, oneOfType, string } from 'prop-types';

import { dynamicBackgroundColor, typeWiseIcon, typeWiseText } from 'utils/Constant';
import { AppButton, AppIcon, AppRowContainer } from '.';

const { Title } = Typography;

/**
 * ConfirmModal Component displays a modal for confirming actions.
 *
 * @param {Object} props - The component's properties.
 * @param {boolean} props.isVisible - Whether the modal is visible.
 * @param {string} props.type - The type of confirmation modal ('success', 'error', 'warning', 'info').
 * @param {string|object} props.title - The title or JSX element to display in the modal.
 * @param {number} [props.width] - The width of the modal.
 * @param {string} [props.cancelText] - The text for the cancel button.
 * @param {string} [props.okText] - The text for the OK button.
 * @param {boolean} [props.loading] - Whether the OK button should show a loading state.
 * @param {function} props.onCancelClick - The function to call when the cancel button is clicked.
 * @param {function} props.onOkClick - The function to call when the OK button is clicked.
 *
 * @returns {JSX.Element} The rendered ConfirmModal component.
 */

const ConfirmModal = ({
  isVisible,
  type,
  title,
  onOkClick,
  width,
  onCancelClick,
  okText = 'Okay',
  cancelText = 'Cancel',
  loading = false,
}) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onCancelClick}
      centered
      width={width}
      footer={[
        <Space key="cancel-btn" align="end" style={{ marginTop: '2rem', display: 'flex', justifyContent: 'end' }}>
          <AppButton type="text" onClick={onCancelClick}>
            {cancelText}
          </AppButton>
          <AppButton onClick={onOkClick} loading={loading}>
            {okText}
          </AppButton>
        </Space>,
      ]}>
      <AppRowContainer justify="center">
        <Space size="small" align="center" direction="vertical">
          <Avatar
            size={64}
            style={{
              marginBottom: '1em',
              backgroundColor: dynamicBackgroundColor[type],
            }}>
            <AppIcon icon={typeWiseIcon[type]} style={{ marginTop: '12px', width: '38px', height: '38px' }} />
          </Avatar>

          <Title level={4} style={{ marginBottom: 0 }}>
            {typeWiseText[type]}
          </Title>
          <span style={{ display: 'flex', fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>{title}</span>
        </Space>
      </AppRowContainer>
    </Modal>
  );
};

export default ConfirmModal;

ConfirmModal.propTypes = {
  isVisible: bool.isRequired,
  type: oneOf(['success', 'error', 'warning', 'info']).isRequired,
  title: oneOfType([string, object]).isRequired,
  width: number,
  cancelText: string,
  okText: string,
  onCancelClick: func.isRequired,
  onOkClick: func.isRequired,
  loading: bool,
};
