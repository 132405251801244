import { Typography } from 'antd';
import { func, string } from 'prop-types';

import nothing from 'assets/images/nodata.svg';
import { AppButton } from '.';

const { Text, Title } = Typography;

const EmptyState = ({ title, description, onButtonClick, buttonText }) => {
  return (
    <div className="error-boundary">
      <img src={nothing} alt="No info" width="150" height="auto" className="mb-2" />

      <Title level={5} style={{ maxWidth: '700px', textAlign: 'center' }}>
        {title}
      </Title>

      <Text className="nt-3" style={{ maxWidth: '500px', textAlign: 'center' }}>
        {description}
      </Text>
      {onButtonClick && (
        <AppButton className="mt-1" size="medium" onClick={onButtonClick}>
          {buttonText}
        </AppButton>
      )}
    </div>
  );
};

export default EmptyState;

EmptyState.propTypes = {
  title: string.isRequired,
  description: string,
  onButtonClick: func,
  buttonText: string,
};
