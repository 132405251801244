import { Navigate } from 'react-router-dom';
import { AppNotification } from 'components/AppComponent';

const ErrorHandler = (error) => {
  if (!error?.response?.data?.message) {
    return AppNotification('Something went wrong! Please try again', 'error');
  }

  if (error?.response?.data?.message === 'Session Expired') {
    localStorage.clear();
    window.location.reload();
    <Navigate to="/auth/login" />;
  } else {
    AppNotification(error?.response?.data?.message, 'error');
  }
};

export default ErrorHandler;
