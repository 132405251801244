import { MenuOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Drawer, Dropdown, Layout, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import logo from 'assets/images/ECRM_Logo.svg';
import { AppButton, AppConfirmModal, AppIcon, AppLoader } from 'components/AppComponent';
import AppErrorBoundary from 'components/AppErrorBoundary';
import AppScrollBar from 'components/AppScrollBar';
import LangSelect from 'components/LangSelect';
import Notification from 'components/Notification/Notification';
import MenuTopics from 'container/MenuTopics';
import { useAuthContext } from 'contexts/Auth';
import useIsMainWindow from 'hooks/useIsMainWindow';
import useResponsive from 'hooks/useResponsive';
import { getFirstLetter, getItem } from 'utils/Helper';

const { Header, Sider } = Layout;
const { Title, Text } = Typography;

const DashboardLayout = () => {
  const location = useLocation();
  const modulePathname = location.pathname.split('/')[1];
  const isMain = useIsMainWindow();

  const [navIsVisible, setNavIsVisible] = useState(false);

  const openSideNav = () => {
    setNavIsVisible(true);
  };

  const closeSideNav = () => {
    setNavIsVisible(false);
  };

  const conditionalUI = () => {
    if (modulePathname !== 'audit-ai') {
      return (
        <Layout>
          <Drawer title={<Profile />} placement="left" onClose={closeSideNav} open={navIsVisible} closable={false}>
            <AppScrollBar className="full-height">
              <MenuTopics onClick={closeSideNav} />
            </AppScrollBar>
          </Drawer>

          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsible={true}
            trigger={null}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              left: 0,
              top: 0,
              bottom: 0,
            }}>
            <AppScrollBar className="full-height">
              <Profile />
              <MenuTopics onClick={() => {}} />
              <div className="mt-2" />
            </AppScrollBar>
          </Sider>

          <Layout>
            <Header className="app-standard-header">
              <Button className="menu" type="text" icon={<MenuOutlined />} onClick={openSideNav} />
              <img src={logo} alt="ecrm logo" width="70px" height="auto" />
              <div style={{ display: 'flex', justifyContent: 'center', gap: '25px', alignItems: 'center' }}>
                <Notification />
                <LangSelect />
              </div>
            </Header>

            <Suspense fallback={<AppLoader />}>
              <AppErrorBoundary>
                <Outlet />
              </AppErrorBoundary>
            </Suspense>
            <footer style={{ position: 'sticky', bottom: 0, zIndex: 1000 }}>
              <Card className="center footer-card">
                [{process.env.REACT_APP_STAGE}] Copyright © Prism CRM {dayjs().format('YYYY')}
              </Card>
            </footer>
          </Layout>
        </Layout>
      );
    }

    if (isMain) {
      return (
        <Layout>
          <Drawer title={<Profile />} placement="left" onClose={closeSideNav} open={navIsVisible} closable={false}>
            <AppScrollBar className="full-height">
              <MenuTopics onClick={closeSideNav} />
            </AppScrollBar>
          </Drawer>

          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsible={true}
            trigger={null}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              left: 0,
              top: 0,
              bottom: 0,
            }}>
            <AppScrollBar className="full-height">
              <Profile />
              <MenuTopics onClick={() => {}} />
              <div className="mt-2" />
            </AppScrollBar>
          </Sider>

          <Layout>
            <Header className="app-standard-header">
              <Button className="menu" type="text" icon={<MenuOutlined />} onClick={openSideNav} />
              <img src={logo} alt="ecrm logo" width="70px" height="auto" />
              <div style={{ display: 'flex', justifyContent: 'center', gap: '25px', alignItems: 'center' }}>
                <Notification />
                <LangSelect />
              </div>
            </Header>

            <Suspense fallback={<AppLoader />}>
              <AppErrorBoundary>
                <Outlet />
              </AppErrorBoundary>
            </Suspense>
            <footer style={{ position: 'sticky', bottom: 0, zIndex: 1000 }}>
              <Card className="center footer-card">
                [{process.env.REACT_APP_STAGE}] Copyright © Prism CRM {dayjs().format('YYYY')}
              </Card>
            </footer>
          </Layout>
        </Layout>
      );
    } else {
      return (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 20px',
            textAlign: 'center',
          }}>
          <AppIcon icon="icon-park-solid:play-wrong" style={{ height: '4rem', width: '4rem', color: 'gray' }} />
          <h1 style={{ color: 'gray' }}>Multiple Tab Detected!</h1>
          <h3 style={{ color: 'gray' }}>
            You have opened audit-ai in multiple tab. Please use the main tab or close that one to use here.
          </h3>
          <AppButton onClick={() => window.location.reload()}>Refresh to use audit ai</AppButton>
        </div>
      );
    }
  };

  return conditionalUI();
};

export default DashboardLayout;

function Profile() {
  const { t } = useTranslation();
  const deviceState = useResponsive();
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const navigateToRespectiveDetailsPage = () => {
    const userType = getItem(localStorage, 'user_type');

    if (userType === 'org_mgt') {
      navigate(`/user-manager/user-details/${getItem(localStorage, 'user_id')}`);
    } else {
      navigate(`/ff-manager/ff-details/${getItem(localStorage, 'user_id')}`);
    }
  };

  const items = [
    {
      key: 1,
      label: t('int-account-profile'),
      onClick: navigateToRespectiveDetailsPage,
      icon: <AppIcon icon="solar:user-bold" />,
    },
    {
      key: 2,
      label: t('int-security'),
      onClick: () => navigate('/manager/security'),
      icon: <AppIcon icon="material-symbols:security" />,
    },
    {
      type: 'divider',
    },
    {
      key: 3,
      label: t('int-logout'),
      danger: true,
      onClick: () => setShowConfirmModal(true),
      icon: <AppIcon icon="ic:round-logout" />,
    },
  ];

  return (
    <div
      style={{
        background: '#fff',
        padding: `${!deviceState.isDesktop ? '0' : '0 16px 10px 24px'}`,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: `${deviceState.isDesktop ? '1px solid #f0f0f0' : 'none'}`,
      }}>
      <Dropdown
        menu={{
          items,
        }}>
        <Space style={{ cursor: 'pointer' }}>
          <Avatar size="large" shape="circle" style={{ backgroundColor: '#005BD4', fontSize: '1.5em' }}>
            {getFirstLetter(getItem(localStorage, 'username'))}
          </Avatar>
          <Title level={5} className="mt-1" style={{ fontWeight: 500 }}>
            {getItem(localStorage, 'username')} <br />
            <Text ellipsis className="nt-4" style={{ fontWeight: 'normal' }}>
              {getItem(localStorage, 'role_name')}
            </Text>
          </Title>
          <AppIcon icon="uil:angle-down" style={{ marginTop: -15 }} />
        </Space>
      </Dropdown>

      <AppConfirmModal
        isVisible={showConfirmModal}
        type="warning"
        title="Are you sure you want to logout?"
        okText="Logout"
        cancelText="Keep me logged in"
        onCancelClick={() => setShowConfirmModal(false)}
        onOkClick={logout}
      />
    </div>
  );
}
