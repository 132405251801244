import { Spin } from 'antd';

import './index.style.scss';

const AppLoader = () => {
  return (
    <div className="app-loader">
      <Spin />
    </div>
  );
};

export default AppLoader;
