import { string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { message, Upload } from 'antd';
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';

import { AppNotification } from 'components/AppComponent';
import { UploadManagerAPI } from 'utils/APIConfig';
import { getItem } from 'utils/Helper';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file.');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 5MB.');
  }

  return isJpgOrPng && isLt2M;
};

const UploadAvatar = ({ imageLink, name, title = 'Upload' }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [storage, setStorage] = useState('');

  useEffect(() => {
    if (
      typeof localStorage.getItem(imageLink) === 'string' &&
      localStorage.getItem(imageLink) != 'null' &&
      localStorage.getItem(imageLink) != ''
    )
      setImageUrl(`${process.env.REACT_APP_cdn}${localStorage.getItem(imageLink)}`);
    setStorage(imageLink);
  }, [imageLink, localStorage.getItem(imageLink)]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <FileImageOutlined />}
      <div>{title}</div>
    </div>
  );

  return (
    <div className="upload">
      <Upload
        name={name}
        listType="picture-card"
        showUploadList={false}
        action={(file) => {
          let data = new FormData();
          data.append(name, file);
          setLoading(true);

          UploadManagerAPI.post('/files', data, {
            headers: {
              'Content-Type': `multipart/form-data;`,
              Authorization: `Bearer ${getItem(localStorage, 'token')}`,
            },
          })
            .then((response) => {
              localStorage.setItem(storage, response.data.file_name);
              setImageUrl(`${process.env.REACT_APP_cdn}${response.data.file_name}`);
            })
            .catch((error) => {
              AppNotification(error, 'error');
            })
            .finally(() => setLoading(false));
        }}
        beforeUpload={beforeUpload}>
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

UploadAvatar.propTypes = {
  imageLink: string,
  name: string,
  title: string,
};

export default UploadAvatar;
