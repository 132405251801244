import { element, func, object, oneOfType, string } from 'prop-types';

import { Icon } from '@iconify/react';

export default function AppIcon({ icon, onClick, color = '', className, style, ...other }) {
  return (
    <Icon
      className={className}
      icon={icon}
      onClick={onClick}
      color={color}
      style={{ cursor: 'pointer', height: '20px', width: '20px', ...style }}
      {...other}
    />
  );
}

AppIcon.propTypes = {
  icon: oneOfType([element, string]),
  style: object,
  onClick: func,
  color: string,
  className: string,
};
