import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { getItem } from 'utils/Helper';
import { isValidToken } from 'lib/Jwt';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const token = getItem(localStorage, 'token');

  if (isValidToken(token)) {
    return <Navigate to="/manager/home" />;
  }

  return <>{children}</>;
}
