import { Button } from 'antd';
import { node, string } from 'prop-types';

const AppButton = ({ type = 'primary', size = 'large', children, ...others }) => {
  return (
    <Button type={type} size={size} shape="round" {...others}>
      {children}
    </Button>
  );
};

export default AppButton;

AppButton.propTypes = {
  type: string,
  size: string,
  children: node,
};
