import { useState, useEffect } from 'react';

const useOnline = () => {
  const [online, setOnline] = useState(navigator.onLine);

  const offlineHandler = () => {
    setOnline(false);
  };

  const onlineHandler = () => {
    setOnline(true);
  };

  useEffect(() => {
    setOnline(navigator.onLine);
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  return online;
};

export default useOnline;
