import { getItem } from 'utils/Helper';
import AccessProtected from './AccessProtected';

export const hasAccess = (accessNumber) => {
  return getItem(localStorage, 'resource_access')?.split(',')?.includes(accessNumber?.toString());
};

export const protectRoutes = (rootRoutes) =>
  rootRoutes.map((rootRoute) => ({
    path: rootRoute.path,
    element: rootRoute.element,
    children: rootRoute.children.map((route) => ({
      path: route.path,
      element: <AccessProtected key={route.path}>{route.element}</AccessProtected>,
    })),
  }));
