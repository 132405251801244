import axios from 'axios';

const AuthAPI = axios.create({
  baseURL: process.env.REACT_APP_login_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const UserManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_user_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const LocationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_location_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const CampaignManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_campaign_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ReportManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_report_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const UploadManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_upload_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AgencyManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_agency_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const RoleManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_role_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const NotificationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_notification_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const OrganizationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_organization_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ClusterManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_cluster_mapping,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuditAiAPI = axios.create({
  baseURL: process.env.REACT_APP_audit_ai,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuditAiVerificationAPI = axios.create({
  baseURL: process.env.REACT_APP_audit_ai_verification,
  headers: {
    'Content-Type': 'application/json',
  },
});

export {
  AuthAPI,
  UserManagerAPI,
  LocationManagerAPI,
  CampaignManagerAPI,
  ReportManagerAPI,
  UploadManagerAPI,
  AgencyManagerAPI,
  RoleManagerAPI,
  NotificationManagerAPI,
  OrganizationManagerAPI,
  ClusterManagerAPI,
  AuditAiVerificationAPI,
  AuditAiAPI,
};
