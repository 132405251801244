import { Card } from 'antd';
import { array, node, string } from 'prop-types';

const AppCard = ({ title, icons, children, className, ...others }) => {
  return (
    <div className={className}>
      <Card className="custom-card" bordered={false} {...others}>
        {title ? (
          <div className="title-section">
            <span>{title}</span>
            {icons?.length > 0 ? <div className="icons">{icons}</div> : null}
          </div>
        ) : null}
        <div className="description">{children}</div>
      </Card>
    </div>
  );
};

export default AppCard;

AppCard.propTypes = {
  title: node,
  icons: array,
  children: node,
  className: string,
};
